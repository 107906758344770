<template>
  <div class="handbook">
    <van-nav-bar
      title="今日练习"
      left-text=""
      left-arrow
      class="navbar"
      @click-left="goBack"
      @click-right="gohome"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" />
      </template>
    </van-nav-bar>

    <div class="list">
      <!-- 开营准备 -->
      <div
        class="listitme"
        v-for="(item, index) in list"
        :key="index"
        @click="gotoitem(item)"
      >
        <div class="listitme-ctx">
          <!-- 分割线 -->

          <!-- 分割线 -->

          <!-- 第0天 -->
          <img
            v-if="
              item.task_name == '入营指南' ||
              item.task_name == '唤醒情绪觉察力' ||
              item.task_name == '如何更好地觉察情绪' ||
              item.task_name == '情绪合理化' ||
              item.task_name == '情绪能量转化' ||
              item.task_name == '对情绪的积极回应' ||
              item.task_name == '应对情绪的方法' ||
              item.task_name == '总结与拓展' ||
              item.task_name == '积极倾听的开始' ||
              item.task_name == '打开倾听的空间' ||
              item.task_name == '捕捉倾听中的信息' ||
              item.task_name == '深层次的倾听——共情' ||
              item.task_name == '倾听中的支持性回应' ||
              item.task_name == '调整倾听中的阻碍' ||
              item.task_name == '沟通表达的力量' ||
              item.task_name == '从客观事实出发' ||
              item.task_name == '对自己的感受负责' ||
              item.task_name == '良好沟通表达的助力——站在对方的视角' ||
              item.task_name == '多提请求，而不是要求' ||
              item.task_name == '应对沟通中的矛盾' ||
              item.task_name == '幽默的积极力量' ||
              item.task_name == '用适当的方式幽默' ||
              item.task_name == '探索属于你的幽默感' ||
              item.task_name == '幽默的化解力' ||
              item.task_name == '幽默的修复力' ||
              item.task_name == '丰富生活中的积极体验'
            "
            class="icon"
            src="https://lesson.iapeap.com/images/EmotionalValue/qingxuzhinan.png"
            alt=""
          />
          <img
            v-if="
              item.task_name == '对自己的承诺' ||
              item.task_name == '情绪探照灯' ||
              item.task_name == '情绪命名挑战' ||
              item.task_name == '情绪逻辑师' ||
              item.task_name == '情绪探险家' ||
              item.task_name == '情绪的时光机' ||
              item.task_name == '情绪呼吸阀' ||
              item.task_name == '情绪指南秘籍' ||
              item.task_name == '倾听能量显微镜' ||
              item.task_name == '倾听空间建筑师' ||
              item.task_name == '倾听信号转接员' ||
              item.task_name == '共情对讲机' ||
              item.task_name == '倾听回应能量阀' ||
              item.task_name == '倾听保护伞' ||
              item.task_name == '倾听指南秘籍' ||
              item.task_name == '沟通计划书' ||
              item.task_name == '客观的表达者' ||
              item.task_name == '感受代言人' ||
              item.task_name == '同频输出者' ||
              item.task_name == '请求提出者' ||
              item.task_name == '冲突化解力' ||
              item.task_name == '表达指南秘籍' ||
              item.task_name == '幽默的线索' ||
              item.task_name == '幽默的指南针' ||
              item.task_name == '幽默探索号' ||
              item.task_name == '幽默的降落伞' ||
              item.task_name == '幽默的讲述者' ||
              item.task_name == '生活的积极按钮' ||
              item.task_name == '收获总结' ||
              item.task_name == '倾听信号接收员'
            "
            class="icon"
            src="https://lesson.iapeap.com/images/EmotionalValue/qingxuxiaolianxi.png"
            alt=""
          />

          <!-- 分割线 -->

          <img
            v-if="item.task_name == '情景训练场'"
            class="icon"
            src="https://lesson.iapeap.com/images/EmotionalValue/qingjingxunlianchang.png"
            alt=""
          />
          <img
            v-if="item.task_name == '情绪能量测验'"
            class="icon"
            src="https://lesson.iapeap.com/images/EmotionalValue/lanqxjzcy.png"
            alt=""
          />
          <img
            v-if="item.task_name == '情绪日记'"
            class="icon"
            src="https://lesson.iapeap.com/images/EmotionalValue/qingjingriji.png"
            alt=""
          />

          <!-- 分割线 -->
          <div class="ctxtitle">{{ item.task_name }}</div>
          <img
            v-if="item.is_complated == 1"
            class="gou"
            src="https://lesson.iapeap.com/images/EmotionalValue/gou.png"
            alt=""
          />
        </div>
        <img
          v-if="item.is_favourite !== null"
          class="aixin"
          src="https://lesson.iapeap.com/images/EmotionalValue/aixin.png"
          alt=""
          @click.stop="getcancelFavourite(item)"
        />
        <img
          v-if="item.is_favourite == null"
          class="aixin"
          src="https://lesson.iapeap.com/images/EmotionalValue/huiaixin.png"
          alt=""
          @click.stop="getsetFavourite(item)"
        />
      </div>
    </div>

    <img
      class="shenlanyao"
      src="https://lesson.iapeap.com/images/EmotionalValue/shenlanyao.png"
      alt=""
    />
    <div class="app-footer">
      <van-tabbar v-model="active" class="tabbar">
        <van-tabbar-item @click="topracticelist">
          <span>练习列表</span>
          <template #icon="props">
            <img :src="props.active ? icon.active : icon.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click="navigateToassistant">
          <span>练习助手</span>
          <template #icon="props">
            <img :src="props.jnh ? icon.jnh : icon.jnh" /> </template
        ></van-tabbar-item>
      </van-tabbar>
    </div>

    <div class="over" v-if="showover">
      <div class="overbox">
        <img
          v-if="stage == 1"
          src="https://lesson.iapeap.com/images/EmotionalValue/1jie.png"
          alt=""
        />

        <img
          v-if="stage == 2"
          src="https://lesson.iapeap.com/images/EmotionalValue/2jie.png"
          alt=""
        />

        <img
          v-if="stage == 3"
          src="https://lesson.iapeap.com/images/EmotionalValue/3jie.png"
          alt=""
        />

        <img
          v-if="stage == 4"
          src="https://lesson.iapeap.com/images/EmotionalValue/4jie.png"
          alt=""
        />
        <div>恭喜你完成{{ stage }}阶的全部练习</div>
        <button @click="tofillin">完成勋章报告 领取报告</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  homePage,
  getTodayPractice,
  setFavourite,
  cancelFavourite,
  getStageIsComplated,
  memberLog,
} from "../../api/login";

export default {
  data() {
    return {
      active: 2,
      icon: {
        active: "https://lesson.iapeap.com/images/EmotionalValue/renwu.png",
        inactive: "https://lesson.iapeap.com/images/EmotionalValue/renwu.png",
        jnh: "https://lesson.iapeap.com/images/EmotionalValue/jnh.png",
        lxdah: "https://lesson.iapeap.com/images/EmotionalValue/lxdah.png",
      },

      list: [],
      days: "0",
      stage: "",
      accomplish: "0",
      mdeal: "",
      showover: false,
      starttime: "", //  进入页面时间
      endtime: "", //  退出页面时间
      time: "", //   页面累积时间
    };
  },
  created() {
    this.gethomePage();
  },
  methods: {
    // 跳转填写勋章报告
    tofillin() {
      this.$router.push({
        path: "/report/fillin",
        query: { stage: this.stage, ok: 1 },
      });
    },
    // 点击收藏
    getsetFavourite(item) {
      let data = {
        userId: window.localStorage.getItem("userId"),
        id: item.id,
      };
      setFavourite(data).then((res) => {
        this.gethomePage();
      });
    },
    // 取消收藏
    getcancelFavourite(item) {
      let data = {
        userId: window.localStorage.getItem("userId"),
        id: item.id,
      };
      cancelFavourite(data).then((res) => {
        this.gethomePage();
      });
    },
    //获取当天阶段内容
    gethomePage() {
      let data = { userId: window.localStorage.getItem("userId") };
      homePage(data).then((res) => {
        this.days = res.days;

        this.stage = res.stage;
        this.daily_pic = res.daily_pic;
        this.getgetTodayPractice();
        this.getgetStageIsComplated2();
        window.localStorage.setItem("days", this.days);
        window.localStorage.setItem("stage", this.stage);
      });
    },

    // 获取当前阶段是否全部完成
    getgetStageIsComplated2() {
      let data = {
        userId: window.localStorage.getItem("userId"),
        stage: this.stage,
      };
      getStageIsComplated(data).then((res) => {
        this.accomplish = res.data.status;
        this.mdeal = res.data.mdeal;
        if (this.accomplish !== 0 && !this.mdeal) {
          this.showover = !this.showover;
        }
      });
    },
    //获取今日练习列表
    getgetTodayPractice() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "正在加载...",
        forbidClick: true,
      });
      let data = {
        userId: window.localStorage.getItem("userId"),
        stage: this.stage,
        days: this.days,
      };
      getTodayPractice(data).then((res) => {
        this.list = res.data;
        Toast.clear();
      });
    },

    navigateToassistant() {
      this.$router.push("/setout/assistant");
    },
    goBack() {
      this.$router.go(-1);
    },
    gohome() {
      this.$router.push("/");
    },
    topracticelist() {
      this.$router.push({
        path: "/setout/practicelist",
        query: { stage: this.stage },
      });
    },
    gotoitem(item) {
      // console.log(item.task_name);
      // console.log(item.id);
      if (
        item.task_name == "入营指南" ||
        item.task_name == "唤醒情绪觉察力" ||
        item.task_name == "如何更好地觉察情绪" ||
        item.task_name == "情绪合理化" ||
        item.task_name == "情绪能量转化" ||
        item.task_name == "对情绪的积极回应" ||
        item.task_name == "应对情绪的方法" ||
        item.task_name == "总结与拓展" ||
        item.task_name == "积极倾听的开始" ||
        item.task_name == "打开倾听的空间" ||
        item.task_name == "捕捉倾听中的信息" ||
        item.task_name == "深层次的倾听——共情" ||
        item.task_name == "倾听中的支持性回应" ||
        item.task_name == "调整倾听中的阻碍" ||
        item.task_name == "沟通表达的力量" ||
        item.task_name == "从客观事实出发" ||
        item.task_name == "对自己的感受负责" ||
        item.task_name == "良好沟通表达的助力——站在对方的视角" ||
        item.task_name == "多提请求，而不是要求" ||
        item.task_name == "应对沟通中的矛盾" ||
        item.task_name == "幽默的积极力量" ||
        item.task_name == "用适当的方式幽默" ||
        item.task_name == "探索属于你的幽默感" ||
        item.task_name == "幽默的化解力" ||
        item.task_name == "幽默的修复力" ||
        item.task_name == "丰富生活中的积极体验"
      ) {
        this.$router.push({
          path: "/setout/handbook",
          query: { item: item },
        });
      }

      if (
        item.task_name == "情绪探照灯" ||
        item.task_name == "情绪命名挑战" ||
        item.task_name == "情绪逻辑师" ||
        item.task_name == "情绪探险家" ||
        item.task_name == "情绪的时光机" ||
        item.task_name == "情绪呼吸阀" ||
        item.task_name == "情绪指南秘籍" ||
        item.task_name == "倾听能量显微镜" ||
        item.task_name == "倾听空间建筑师" ||
        item.task_name == "倾听信号转接员" ||
        item.task_name == "共情对讲机" ||
        item.task_name == "倾听回应能量阀" ||
        item.task_name == "倾听保护伞" ||
        item.task_name == "倾听指南秘籍" ||
        item.task_name == "沟通计划书" ||
        item.task_name == "客观的表达者" ||
        item.task_name == "感受代言人" ||
        item.task_name == "同频输出者" ||
        item.task_name == "请求提出者" ||
        item.task_name == "冲突化解力" ||
        item.task_name == "表达指南秘籍" ||
        item.task_name == "幽默的线索" ||
        item.task_name == "幽默的指南针" ||
        item.task_name == "幽默探索号" ||
        item.task_name == "幽默的降落伞" ||
        item.task_name == "幽默的讲述者" ||
        item.task_name == "生活的积极按钮" ||
        item.task_name == "收获总结" ||
        item.task_name == "倾听信号接收员"
      ) {
        if (item.is_complated == 1) {
          this.$router.push({
            path: "/setout/ckninjia",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/setout/ninjia",
            query: { item: item },
          });
        }
      }
      if (item.task_name == "情景训练场") {
        this.$router.push({
          path: "/setout/trainingground",
          query: { item: item },
        });
        window.localStorage.setItem("listid", item.id);
      }
      if (item.task_name == "情绪日记") {
        if (item.is_complated !== 1) {
          this.$router.push({
            path: "/emotion/diary",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/emotion/ckdiary",
            query: { item: item },
          });
        }
      }
      if (item.task_name == "对自己的承诺") {
        this.$router.push({
          path: "/setout/mepromise",
          query: { item: item },
        });
      }
      if (item.task_name == "情绪能量测验") {
        // this.$router.push({
        //   path: "/test/worth",
        //   query: { item: item },
        // });
        if (this.days == "0") {
          this.$router.push({
            path: "/test/worth",
            query: { item: item },
          });
        } else {
          this.$router.push({
            path: "/welcomeH",
            query: { item: item },
          });
        }
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.time++;
      // console.log("页面累积浏览时间是：",this.time,"秒")
    }, 1000); // 每秒触发一次
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    this.starttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("进入页面的系统时间是：", this.starttime);
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
    // ```````````获取当前的年月日时分秒，并以"2018-01-01 0:00"的格式显示
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // 补0操作，确保是两位数
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    this.endttime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log("离开页面的系统时间是", this.endttime);

    //把参数传给后端接口，starttime、endttime、time
    let data = {
      startTime: this.starttime,
      endTime: this.endttime,
      userId: window.localStorage.getItem("userId"),
      pageName: "今日练习",
      timeStamp: this.time,
    };
    memberLog(data).then((res) => {});
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.tabbar {
  height: 72px;
}
.shenlanyao {
  width: 72px;
  position: absolute;
  bottom: 72px;
  right: 40px;
  z-index: 0;
}
.handbook {
  background-color: #f4fafe;
  min-height: calc(100vh - 72px);
  .list {
    position: absolute;
    width: 100%;
    padding: 30px 20px 0px 20px;
    box-sizing: border-box;
    z-index: 999;
  }
  .listitme {
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    border-radius: 8px;
    padding: 28px 32px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .ctxtitle {
      height: 25px;
      font-size: 18px;
      font-weight: 500;
      color: #000025;
      line-height: 25px;
    }
    .listitme-ctx {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .icon {
      width: 23px;
      height: 23px;
    }
    .gou {
      width: 22px;
      height: 18px;
    }
    .aixin {
      width: 21px;
      height: 18px;
      float: right;
    }
  }
  .over {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgba(250, 253, 254, 0.9);

    .overbox {
      width: 300px;
      height: 304px;
      background-color: #fff;
      box-shadow: 1px 4px 4px 2px rgba(0, 0, 0, 0.192);
      border-radius: 25px;
      text-align: center;
      color: #333;
      img {
        position: relative;
        // left: calc(50% - 75px);
        bottom: 30px;
        width: 150px;
        height: 140px;
      }
      button {
        color: #fff;
        border-radius: 25px;
        background-color: #72acf4;
        width: 224px;
        height: 36px;
        border: 0;
        margin-top: 30px;
      }
    }
  }
}
</style>
